$('.main-img-slider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    arrows: true,
    fade: true,
    dots: false,
    adaptiveHeight: false,
    speed: 300,
    lazyLoad: 'ondemand',
    asNavFor: '.thumb-nav',
    prevArrow: '<div class="slick-prev"><i class="icon-chev-left-thin-double"></i><span class="sr-text">Precedente</span></div>',
    nextArrow: '<div class="slick-next"><i class="icon-chev-right-thin-double"></i><span class="sr-text">Successivo</span></div>'
});

$('.thumb-nav').slick({
    centerMode: false,
    centerPadding: '5px',
    variableWidth: true,
    slidesToScroll: 1,
    slidesToShow: 5,
    infinite: true,
    asNavFor: '.main-img-slider',
    dots: false,
    adaptiveHeight: true,
    draggable: true,
    speed: 200,
    focusOnSelect: true,
    arrows: false,
    // prevArrow: '<div class="slick-prev"><i class="icon-chev-left-thin-double"></i><span class="sr-text">Precedente</span></div>',
    // nextArrow: '<div class="slick-next"><i class="icon-chev-right-thin-double"></i><span class="sr-text">Successivo</span></div>'
});

$('.main-img-slider').on('afterChange', function (event, slick, currentSlide, nextSlide) {
    $('.thumb-nav .slick-slide').removeClass('slick-current');
    $('.thumb-nav .slick-slide:not(.slick-cloned)').eq(currentSlide).addClass('slick-current');
});

$('.main-img-slider').magnificPopup({
    inline: true,
    delegate: 'img',
    type: 'image',
    gallery: {
        enabled: true, // set to true to enable gallery

        preload: [0, 2], // read about this option in next Lazy-loading section

        navigateByImgClick: true,
        arrowMarkup: '<button title="%title%" type="button" class="mfp-arrow mfp-arrow-%dir%"></button>', // markup of an arrow button

        tPrev: 'Precedente', // title for left button
        tNext: 'Successiva', // title for right button
        tCounter: '<span class="mfp-counter">%curr% of %total%</span>' // markup of counter
    },
    callbacks: {
        change: function () {
            $(".main-img-slider").slick( "slickGoTo", $.magnificPopup.instance.index);
        }
    }
});
