$(document).ready(function() {

    $("img.lazy").lazyload({
        effect : "fadeIn"
    });

  // Fullpage search
  // --------------------------------------------------
  function fullpageSearch() {

    $('#search-opener').on('click', function(event) {
        event.preventDefault();
        $('#search').addClass('js-open');
        $('#search > form > input[type="search"]').focus();
    });

    $('#search, #search button.js-close').on('click keyup', function(event) {
        if (event.target == this || event.target.className == 'js-close' || event.keyCode == 27) {
            $(this).removeClass('js-open');
        }
    });

    //$('form').submit(function(event) {
    //    event.preventDefault();
    //    return false;
    //})
  }
  fullpageSearch();


  //photogallery-home
  // --------------------------------------------------
  // handles the carousel thumbnails
  $('[id^=carousel-selector-]').click( function(){
    var id_selector = $(this).attr("id");
    var id = id_selector.substr(id_selector.length -1);
    id = parseInt(id);
    $('#photogallery-home').carousel(id);
    $('[id^=carousel-selector-]').removeClass('selected');
    $(this).addClass('selected');
    });

    // when the carousel slides, auto update
    $('#photogallery-home').on('slid', function (e) {
    var id = $('.item.active').data('slide-number');
    id = parseInt(id);
    $('[id^=carousel-selector-]').removeClass('selected');
    $('[id=carousel-selector-'+id+']').addClass('selected');
  });

  $(".carousel").touchwipe({
    wipeLeft: function() { $(".carousel").carousel('next'); },
    wipeRight: function() { $(".carousel").carousel('prev'); },
    min_move_x: 20,
    preventDefaultEvents: false  // done the magic
  });

  // FULLSCREEN
  $('.js-open-fullscreen').on('click', function(e){
    photoSwipe();
  });


  function photoSwipe(){
    var $pswp = $('.pswp')[0];


    // Popolo l'array con

    //chiamata API oppure ciclo for tra gli #items

      var items = [
        {
          src: 'img/slides/image1.jpg', // path to image
          w: 1024, // image width
          h: 768
        },
        {
          src: 'img/slides/image2.jpg', // path to image
          w: 1024, // image width
          h: 768
        },
        {
          src: 'img/slides/image3.jpg', // path to image
          w: 1024, // image width
          h: 768
        }

      ]



    var $index = $(this).index();

    var options = {
        index: $index,
        closeEl:true,
        captionEl: true,
        fullscreenEl: false,
        zoomEl: true,
        shareEl: true,
        counterEl: true,
        arrowEl: true,
        preloaderEl: true
    }

    var goFullschreen = new PhotoSwipe($pswp, PhotoSwipeUI_Default, items, options);
    goFullschreen.init();

  }

  // Read more
  // --------------------------------------------------
  $('.js-readmore').on('click', function(){
    toggleElm = $(this).data('toggle');
    // $('.' + toggleElm).css('max-height', 'inherit');
    $('.' + toggleElm).toggleClass('js-open');

    $(this).toggleClass('js-open');
  });


  //Close nav when click link
  // $(document).on('click','.navbar-collapse.in',function(e) {
  //     if( $(e.target).is('a') && $(e.target).attr('class') != 'dropdown-toggle' ) {
  //         $(this).collapse('hide');
  //     }
  // });

  // Footer GMaps
  // --------------------------------------------------
  function init() {
      var vdp = new google.maps.LatLng(45.5334835,10.2279035);

      var isDraggable = $(document).width() > 480 ? true : false;

      var mapOptions = {
        // Zoom
        zoom: 17,
        draggable: isDraggable,
        scrollwheel: false,
        center: vdp,
      };
      var mapElement = document.getElementById('footer-map');

      // Create the Google Map using our element and options defined above
      var map = new google.maps.Map(mapElement, mapOptions);

      // Let's also add a marker while we're at it
      var marker = new google.maps.Marker({
          position: vdp,
          map: map,
          animation: google.maps.Animation.DROP,
          title: 'Voce Del Popolo'
      });
  }


  // Opinioni Carousel: da mettere solo in home page
  // --------------------------------------------------
  $('#opinion-carousel').carousel({ interval: false });

  $('.carousel-showmanymoveone .item').each(function(){
    var itemToClone = $(this);

    for (var i=1;i<3;i++) {
      itemToClone = itemToClone.next();
      if (!itemToClone.length) {
        itemToClone = $(this).siblings(':first');
      }
      itemToClone.children(':first-child').clone()
        .addClass("cloneditem-"+(i))
        .appendTo($(this));
    }
  });

  // Navbarfixed when scroll
  // --------------------------------------------------



  function scrollMenu() {
    // Listener scroll
    $(window).scroll(function() {
      var scrollTop = $(this).scrollTop();

      if (scrollTop >= 84) {
        $('#main-nav').addClass('js-navfixed');
      }else{
        $('#main-nav').removeClass('js-navfixed');
      }

    });
  }
  scrollMenu();

    if ($("#acec-cinema")[0]) {
        (new AcecCinema("#acec-cinema",phpVariables.acecCinemaUrl));
    }


  if($("#ads_desktop")[0]){
      var adsDesktop = $("#ads_desktop")
      if(adsDesktop[0].offsetWidth === 0 || adsDesktop[0].offsetWidth < 263){
          adsDesktop.attr('src', adsDesktop.data('src-fallback'))
      }
  }
});
